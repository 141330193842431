import React, { useCallback, useState } from "react";
import { Card, Button, TextField, Spinner } from "@shopify/polaris";
import MarkdownPreview from "@uiw/react-markdown-preview";
import DateRangePicker from "../common/date-picker";
import { initialPrompt } from "../../data";
import { useMutation } from "@tanstack/react-query";

const ChatInsights = () => {
  const [prompt, setPrompt] = useState(initialPrompt);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: undefined,
    endDate: undefined,
  });

  const handleChangePrompt = useCallback((newValue) => setPrompt(newValue), []);

  const handleDateRangeChange = (sDate, eDate) => {
    setDateRange({ startDate: sDate, endDate: eDate });
  };

  // Mutations
  const mutation = useMutation({
    mutationFn: async (formData) => {
      const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content");

      const response = await fetch("/generate-insights", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorJson = await response.json();
        throw new Error(errorJson.message);
      }

      return response.json();
    },
    onSuccess: (resultJson) => {
      setData(resultJson.insights);
      if (error) setError(null);
    },
    onError: (error) => {
      setError(error.message || error);
    },
  });

  const handleSubmit = () => {
    if (!dateRange || !dateRange.startDate || !dateRange.endDate) {
      return setError("Date range is empty, please select a date range");
    }

    setError(null);
    const formData = {
      prompt,
      start_date: dateRange.startDate,
      end_date: dateRange.endDate,
    };
    mutation.mutate(formData);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {error ? (
        <div
          className="alert alert-danger text-danger text-center"
          style={{ margin: "0" }}
        >
          {error}
        </div>
      ) : null}

      <Card>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <DateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onDateRangeChange={handleDateRangeChange}
            />
          </div>
          <div style={{ width: "100%" }}>
            <TextField
              label="Enter Prompt"
              value={prompt}
              onChange={handleChangePrompt}
              multiline={4}
              autoComplete="off"
            />
          </div>
          <div style={{ margin: "0.5rem auto 0" }}>
            {mutation.isPending ? (
              <Spinner accessibilityLabel="Spinner" size="small" />
            ) : (
              <Button variant="primary" onClick={handleSubmit} size="large">
                Submit
              </Button>
            )}
          </div>
        </div>
      </Card>

      {data ? (
        <Card title="Generated Insights" sectioned>
          <MarkdownPreview source={data} style={{ padding: 16 }} />
        </Card>
      ) : null}
    </div>
  );
};

export default ChatInsights;
