import React, { useState, useRef } from "react";
import dayjs from "dayjs";
import {
  Popover,
  Button,
  InlineGrid,
  Box,
  BlockStack,
  InlineStack,
  Icon,
  DatePicker,
  TextField,
} from "@shopify/polaris";
import { CalendarIcon } from "@shopify/polaris-icons";

// Parse 'YYYY-MM-DD' string into a Date object
function parseDateFromString(input) {
  return dayjs(input, "YYYY-MM-DD").toDate();
}

// Validate date format
function isValidDate(date) {
  return dayjs(date, "YYYY-MM-DD", true).isValid();
}

const DateRangePicker = ({ startDate, endDate, onDateRangeChange }) => {
  // Variables
  const today = dayjs().startOf("day");

  // Ref
  const datePickerRef = useRef(null);

  // States
  const [popoverActive, setPopoverActive] = useState(false);
  const [{ month, year }, setDate] = useState({
    month: startDate || today.get("month"),
    year: startDate || today.get("year"),
  });

  /// Functions

  function handlePopoverChange(value) {
    if (value !== undefined && value !== null) {
      setPopoverActive(value);
      return;
    }
    setPopoverActive(!popoverActive);
  }

  function handleInputValueChange(inputName, value) {
    if (inputName === "start-date" && isValidDate(value)) {
      const newSince = parseDateFromString(value);
      const newUntil = newSince <= endDate ? endDate : newSince;
      onDateRangeChange(newSince, newUntil);
      setDate({
        month: endDate.getMonth(),
        year: endDate.getFullYear(),
      });
    } else if (inputName === "end-date" && isValidDate(value)) {
      const newUntil = parseDateFromString(value);
      const newSince = newUntil >= startDate ? startDate : newUntil;
      onDateRangeChange(newSince, newUntil);
      setDate({
        month: endDate.getMonth(),
        year: endDate.getFullYear(),
      });
    }
  }

  function handleInputBlur({ relatedTarget }) {
    if (!relatedTarget || !datePickerRef?.current.contains(relatedTarget)) {
      setPopoverActive(false);
    }
  }

  function handleMonthChange(month, year) {
    setDate({ month, year });
  }

  function handleCalendarChange({ start, end }) {
    onDateRangeChange(start, end);
  }

  const buttonValue =
    startDate && endDate
      ? `${dayjs(startDate).format("MMM D, YYYY")} - ${dayjs(endDate).format(
          "MMM D, YYYY"
        )}`
      : "Select Date Range";

  return (
    <Popover
      active={popoverActive}
      autofocusTarget="none"
      preferredAlignment="left"
      preferredPosition="below"
      fluidContent
      sectioned={false}
      fullHeight
      activator={
        <Button size="large" icon={CalendarIcon} onClick={handlePopoverChange}>
          {buttonValue}
        </Button>
      }
      onClose={() => handlePopoverChange(false)}
    >
      <Popover.Pane fixed>
        <div ref={datePickerRef}>
          <InlineGrid
            columns={{
              xs: "1fr",
              mdDown: "1fr",
              md: "max-content max-content",
            }}
            gap={0}
          >
            <Box padding="300" maxWidth="600px">
              <BlockStack gap="400">
                <InlineStack gap="200" align="center">
                  <div style={{ flexGrow: 1 }}>
                    <TextField
                      role="combobox"
                      label="Start Date"
                      name="start-date"
                      prefix={<Icon source={CalendarIcon} />}
                      value={startDate && dayjs(startDate).format("YYYY-MM-DD")}
                      onChange={(value) =>
                        handleInputValueChange("start-date", value)
                      }
                      onBlur={handleInputBlur}
                      autoComplete="off"
                    />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <TextField
                      role="combobox"
                      label="End Date"
                      name="end-date"
                      prefix={<Icon source={CalendarIcon} />}
                      value={endDate && dayjs(endDate).format("YYYY-MM-DD")}
                      onChange={(value) =>
                        handleInputValueChange("end-date", value)
                      }
                      onBlur={handleInputBlur}
                      autoComplete="off"
                    />
                  </div>
                </InlineStack>
                <div id="date_range__picker">
                  <DatePicker
                    month={month}
                    year={year}
                    multiMonth
                    allowRange
                    selected={
                      (startDate || endDate) && {
                        start: startDate,
                        end: endDate,
                      }
                    }
                    onMonthChange={handleMonthChange}
                    onChange={handleCalendarChange}
                  />
                </div>
              </BlockStack>
            </Box>
          </InlineGrid>
        </div>
      </Popover.Pane>

      <Popover.Pane fixed>
        <Popover.Section>
          <InlineStack align="end">
            <Button
              variant="primary"
              onClick={() => handlePopoverChange(false)}
            >
              Done
            </Button>
          </InlineStack>
        </Popover.Section>
      </Popover.Pane>
    </Popover>
  );
};

export default DateRangePicker;
