import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import ChatInsights from "../../components/chat_insights";

// Create a client
const queryClient = new QueryClient();

document.addEventListener("DOMContentLoaded", () => {
  const element = document.getElementById("chat_insights");

  if (element) {
    const root = createRoot(element);
    root.render(
      <StrictMode>
        <QueryClientProvider client={queryClient}>
          <AppProvider i18n={enTranslations}>
            <ChatInsights />
          </AppProvider>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </StrictMode>
    );
  }
});
